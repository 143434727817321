<script setup>
import { inject, ref } from 'vue';

import StaffLayout from '@/Layouts/StaffLayout.vue';
import FormSubject from '@/Components/Form/FormSubject.vue';
import Button from '@/Components/Button/Button.vue';
import ModalSalaryNotification from './Partials/ModalSalaryNotification.vue';

const route = inject('route');

const showConfirmationModal = ref(false);

const sendForm = () => {
    showConfirmationModal.value = true;
};

</script>

<template>
    <StaffLayout :title="$t('Management')">
        <FormSubject :title="$t('Send salary notification to temporary workers')">
            <div class="my-5">
                {{ $t('When you send this notification all temp workers that have worked in the previous 7 days will receive a notification that their salary has been paid.') }}
            </div>
            <div class="flex justify-end">
                <Button
                    type="button"
                    @click="sendForm"
                    :text="$t('Send payment notification')"
                />
            </div>
        </FormSubject>
    </StaffLayout>
    <ModalSalaryNotification v-model:showing="showConfirmationModal" />
</template>
